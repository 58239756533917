// @flow
import * as React from 'react'
import { CTAPrimaryLink } from './shared/buttons'
import styled from 'styled-components'
import {
  config,
  Container,
  Row,
  Col,
  Visible,
  ThemedStyledProps
} from 'react-awesome-styled-grid'
import type { AppStateType } from '../reducers/appstate'
import { withRouter } from 'next/router'
import { connect } from 'react-redux'
import { LinkWithUtms } from '../actions/navigationActions'
import {
  DipseaBrandmark,
  TwitterIcon,
  InstagramIcon,
  TikTokIcon
} from './shared/icons'
import { colors } from './shared/colors'
import { Accordion } from './Accordion'
import { P } from './shared/typography'

type PropsType = {
  backgroundColor?: string
}

function Footer (props: PropsType): React.Node {
  const accordionContent = [
    { key: 'footer-about-us', title: 'ABOUT US', body: aboutUsLinks() },
    { key: 'footer-reach-us', title: 'DISCOVER', body: discoverLinks() },
    { key: 'footer-account', title: 'ACCOUNT', body: accountLinks() },
    { key: 'footer-support', title: 'SUPPORT', body: supportLinks() }
  ]
  return (
    <footer style={{ backgroundColor: props.backgroundColor, zIndex: 1, paddingTop: 60 }}>
      <Container>
        <Row>
          <Col xs={4} sm={8} md={12}>
            <Main>
              <LinksContainer>
                <HomeLink href='/' aria-label='Dipsea, opens homepage'>
                  <DipseaBrandmark color={colors.sand} />
                </HomeLink>

                <Visible xs={true} sm={true} md={true} lg={false} xl={false}>
                  <Accordion accordionContent={accordionContent} />
                </Visible>

                <Visible xs={false} sm={false} md={false} lg={true} xl={true}>
                  <NavSection>
                    <SectionHeader>ABOUT US</SectionHeader>
                    {aboutUsLinks()}
                  </NavSection>

                  <NavSection>
                    <SectionHeader>DISCOVER</SectionHeader>
                    {discoverLinks()}
                  </NavSection>

                  <NavSection>
                    <SectionHeader>ACCOUNT</SectionHeader>
                    {accountLinks()}
                  </NavSection>

                  <NavSection>
                    <SectionHeader>SUPPORT</SectionHeader>
                    {supportLinks()}
                  </NavSection>
                </Visible>
              </LinksContainer>

              <ButtonsContainer>
                <CTAPrimaryLink href="/discover">
                  START LISTENING
                </CTAPrimaryLink>
                <SocialContainer>
                  <a
                    href='https://www.tiktok.com/@dipseastories'
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label='Tiktok'
                  >
                    <TikTokIcon />
                  </a>
                  <a
                    className='center'
                    href='https://www.instagram.com/dipseastories/'
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label='Instagram'
                  >
                    <InstagramIcon />
                  </a>
                  <a
                    href='https://twitter.com/DipseaStories'
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label='Twitter'
                  >
                    <TwitterIcon />
                  </a>
                </SocialContainer>
              </ButtonsContainer>
            </Main>

            <Bottom>
              <div>
                <LinkWithUtms pathname='/privacypolicy'>Privacy Policy</LinkWithUtms>
                <LinkWithUtms pathname='/terms'>Terms & Conditions</LinkWithUtms>
              </div>
              <FooterText>© 2024 Dipsea Inc. All Rights Reserved</FooterText>
            </Bottom>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

const aboutUsLinks = (): React.Node => {
  return (
    <ul>
      <li>
        <LinkWithUtms pathname="/about">About Us</LinkWithUtms>
      </li>
      <li>
        <LinkWithUtms pathname="/blog">Blog</LinkWithUtms>
      </li>
      <li>
        <LinkWithUtms pathname="/blog/dipsea-faq">Why Dipsea?</LinkWithUtms>
      </li>
      <li>
        <a href="mailto:press@dipseastories.com">Press</a>
      </li>
    </ul>
  )
}

const discoverLinks = (): React.Node => {
  return (
    <ul>
      <li>
        <LinkWithUtms pathname="/series">Top Audiobooks</LinkWithUtms>
      </li>
      <li>
        <LinkWithUtms pathname="/discover">Browse</LinkWithUtms>
      </li>
      <li>
        <LinkWithUtms pathname="/characters">Characters</LinkWithUtms>
      </li>
      <li>
        <LinkWithUtms pathname="/books">Books</LinkWithUtms>
      </li>
      <li>
        <LinkWithUtms pathname="/search">Search</LinkWithUtms>
      </li>
    </ul>
  )
}

const accountLinks = (): React.Node => {
  return (
    <ul>
      <li>
      <LinkWithUtms pathname={'/account'}>Sign In</LinkWithUtms>
      </li>
      <li>
        <LinkWithUtms pathname={'/account/subscription'}>
          Manage Subscription
        </LinkWithUtms>
      </li>
      <li>
        <LinkWithUtms pathname="/gift">Gift Dipsea</LinkWithUtms>
      </li>
      <li>
        <LinkWithUtms pathname="/redeem">Redeem a Gift</LinkWithUtms>
      </li>
    </ul>
  )
}

const supportLinks = (): React.Node => {
  return (
    <ul>
      <li>
        <LinkWithUtms pathname="/blog/dipsea-faq">FAQ</LinkWithUtms>
      </li>
      <li>
        <a
          href='https://support.dipseastories.com/en/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Help
        </a>
      </li>
    </ul>
  )
}

const SectionHeader = styled(P)`
  color: ${colors.sand};
  font-size: 14px;
  font-family: 'Good Sans Medium';
  letter-spacing: 0.03em;
  margin: -4px 0 15px;
  z-index: 1;
`
const FooterText = styled(P)`
  color: ${colors.sand};
  font-family: 'Good Sans Light';
  font-size: 13px;
  margin: 10px 0 0;
  ${(props: ThemedStyledProps): string => config(props).media.lg`
    margin: 0;
  `}
`
const linkStyle = `
  text-decoration: none;
  cursor: pointer;
  color: ${colors.sand};
  font-family: "Good Sans Light";
  font-size: 14px;
  outline: none;
  border: 1px solid transparent;
  transition: all 0.3s;
   border-bottom: 1px solid transparent;
  :hover {
    color: ${colors.orange};
    opacity: 1;
  }
  :focus-visible {
    color: ${colors.orange};
    opacity: 1;
    border-bottom: 1px solid ${colors.orange};
  }
`
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 72px;
  a {
    ${linkStyle};
    margin: 0 10px;
  }
  ${(props: ThemedStyledProps): string => config(props).media.lg`
    flex-direction: row;
    justify-content: space-between;
    a {
      margin: 0 0 0 25px;
    }
  `}
`
const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  ${(props: ThemedStyledProps): string => config(props).media.lg`
    align-items:flex-end;
  `}

  button {
    width: 100%;
    ${(props: ThemedStyledProps): string => config(props).media.lg`
      width: 232px;
    `}
  }
`
const NavSection = styled.section`
  margin: 0 5px;
  ul {
    margin: 0;
    padding: 0;
    li {
      display: list-item;
      text-align: -webkit-match-parent;
      list-style: none !important;
      padding: 0;
      margin: 0 0 1em;
      a {
        ${linkStyle};
      }
    }
  }
`
const HomeLink = styled.a`
  border-radius: 8px;
  margin: -5px 15px -5px -5px;
  outline: none;
  padding: 5px;
  align-self: center;
  ${(props: ThemedStyledProps): string => config(props).media.lg`
    align-self: flex-start;
  `}
  :hover {
    opacity: 0.8;
  }
  :focus-visible {
    opacity: 1;
    svg {
      path {
        transition: all 0.3s;
        fill: ${colors.orange};
      }
    }
  }
`
const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 70px 0 0;
  z-index: 1;

  ${(props: ThemedStyledProps): string => config(props).media.lg`
    justify-content: space-between;
    padding: 55px 0 70px;
    margin: 0;
    flex-direction: row;
  `}
`
const LinksContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  ${(props: ThemedStyledProps): string => config(props).media.lg`
    align-items: flex-start;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 40px;
    max-width: 790px;
  `}
`
const SocialContainer = styled.div`
  z-index: 1;
  margin: 40px 0 30px;
  display: flex;
  justify-content: flex-end;
  a {
    outline: none;
    border-radius: 15px;
    height: 30px;
    width: 30px;
    :hover,
    :focus {
      opacity: 1;
      svg {
        path {
          transition: all 0.3s;
          fill: ${colors.orange};
        }
      }
    }
  }
  a.center {
    margin: 0 15px 0;
  }
`
const mapStateToProps = (state: AppStateType): any => {
  return {}
}

const mapDispatchToProps = (): any => {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer))
